/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


section:not(.full-width),
.full-width > header {
    padding: 0 10px;
}

/* Shadow class overrides */
ion-item::part(native){
    --border-style: none;
    --background: transparent;
}

ion-modal.modal-fullscreen::part(content) {
    border-radius: 0;
    height: 100%;
  }

.material-icon {
    width: 48px;
    height: 48px;
    zoom: 0.4;
}

/* mobile frame preview on desktop */
@media screen and (min-width: 400px) {
    body {
        background: #e0e0e0 !important;

        app-root {
            max-width: 399px;
            border: 10px solid black;
            border-radius: 10px;
            margin: 30px auto;
            max-height: 700px;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.callUser {
    width: 100%;
}



.swiper-pagination.swiper-pagination-bullets {
    position: absolute;
    bottom: 0;
}

ion-slide.intro_slide {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    ion-text {
        padding: 20px 0;
        display: flex;
        flex-flow: column;
    }
    img {
        max-width: 300px;
        width: 100%;
        margin-top: 20px;
    }
}

span.swiper-pagination-bullet {
    border: 1px solid #007aff;
}

.timer-wrap {
    z-index: 2;
    text-align: center;
    padding: 5px;
    background: #cccccc4d;
    display: flex;
    justify-content: center;
    align-content: center;

    .call-timer {
        margin-bottom: 0px !important;
        font-size: 17px;
    }
}

.safe-area {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}